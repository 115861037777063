import styled from "styled-components";

export const ValidationErrorMessage = styled.div`
color: ${(props) => props.color || "red"};
  font-size: .625rem;
  display: flex;
  align-items: center;
  margin: 8px 0 0 1px;
  line-height: 1;
`;
