import { StyledButtonWithIcon } from "styles/globalStyles";
import { useTheme } from "@mui/material/styles";

interface Props {
  icon: JSX.Element;
  clickHandler?: () => void;
  borderRadius?: string;
}

const ButtonWithIcon = ({ icon, clickHandler, borderRadius }: Props) => {
  const theme = useTheme();

  return (
    <StyledButtonWithIcon
      mainColor={theme.palette.dark.main}
      borderRadius={borderRadius ? borderRadius : ""}
      onClick={clickHandler}
    >
      {icon}
    </StyledButtonWithIcon>
  );
};

export default ButtonWithIcon;
