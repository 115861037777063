import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PermissionProps {
  permission: string;
}

export const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    permission: "all",
  },
  reducers: {
    setPermission: (state, action: PayloadAction<PermissionProps>) => {
      state.permission = action.payload.permission;
    },
  },
});

export const { setPermission } = permissionSlice.actions;

export default permissionSlice.reducer;
