import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SnackBarActionProps {
  open?: boolean;
  severity?: string;
  message?: string;
  horizontal?: string;
  vertical?: string;
}

const initialState = {
  open: false,
  message: "",
  vertical: "top",
  horizontal: "center",
  severity: "",
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    snackbarOpen: (state, action: PayloadAction<SnackBarActionProps>) => {
      state.open = action.payload.open
        ? action.payload.open
        : initialState.open;

      state.message = action.payload.message
        ? action.payload.message
        : initialState.message;
      state.vertical = action.payload.vertical
        ? action.payload.vertical
        : initialState.vertical;

      state.horizontal = action.payload.horizontal
        ? action.payload.horizontal
        : initialState.horizontal;

      state.severity = action.payload.severity
        ? action.payload.severity
        : initialState.severity;
    },
  },
});

export const { snackbarOpen } = snackbarSlice.actions;

export default snackbarSlice.reducer;
