import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ToastProps {
  open: boolean;
  severity: string;
  message: string;
}

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    open: false,
    severity: "",
    message: "",
  },
  reducers: {
    setToastOpen: (state, action: PayloadAction<ToastProps>) => {
      state.open = action.payload.open;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    },
  },
});

export const { setToastOpen } = toastSlice.actions;

export default toastSlice.reducer;
